
import { Component, Vue } from 'vue-property-decorator'
import { Info } from '../../types/workGuide'

@Component({
  name: 'WorkGuide',
  filters: {
    isShowFilters (value: number) {
      return value === 0 ? '否' : value === 1 ? '是' : ''
    }
  }
})
export default class WorkGuide extends Vue {
  private info: Info = {
    workGuideTile: '',
    workGuideIntroduction: '',
    workGuideContent: '',
    tagList: [],
    isShow: null,
    resourceList: [],
    guideType: '',
    guideTypeName: ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.workGuide.workGuideDetail, {
      workGuideId: this.$route.params.id
    }).then(res => {
      this.info = res
    })
  }
}
